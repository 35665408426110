import { mapGetters, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';

import {
  Sidebar,
  Loader,
  Activity,
  GroupCard,
  ClubCard,
  UserActivities,
  ConfirmationModal,
} from '@/components';

export default {
  metaInfo: {
    title: 'Profile page',
  },
  name: 'Profile',
  components: {
    [Sidebar.name]: Sidebar,
    [Loader.name]: Loader,
    [Activity.name]: Activity,
    [GroupCard.name]: GroupCard,
    [ClubCard.name]: ClubCard,
    [UserActivities.name]: UserActivities,
    [ConfirmationModal.name]: ConfirmationModal,
  },
  props: {},
  data: () => ({
    showConfirmationModal: false,
    confirmationModalIsActive: false,
    eventPage: 1,
    groupPage: 1,
    clubPage: 1,
    activityPage: 1,
    userId: '',
    user: '',
    imageObj: '',
    error: false,
  }),
  computed: {
    ...mapGetters('event', {
      events: 'getUserEventList',
      getUserEventFilter: 'getUserEventFilter',
      isLoadingEvents: 'getLoadingState',
      buttonEventsIsLoading: 'getButtonLoadingState',
    }),
    ...mapGetters('activity', {
      activities: 'getUserActivityList',
      getUserActivityFilter: 'getUserActivityFilter',
      isLoadingActivities: 'getLoadingState',
      buttonActivitiesIsLoading: 'getButtonLoadingState',
    }),
    ...mapGetters('group', {
      groups: 'getUserGroupList',
      getUserGroupFilter: 'getUserGroupFilter',
      isLoadingGroups: 'getLoadingState',
      buttonGroupsIsLoading: 'getButtonLoadingState',
    }),
    ...mapGetters('club', {
      clubs: 'getUserClubList',
      getUserClubFilter: 'getUserClubFilter',
      isLoadingClubs: 'getLoadingState',
      buttonClubsIsLoading: 'getButtonLoadingState',
    }),
    ...mapGetters('account', {
      currentUser: 'getCurrentUser',
      isLoadingForgotPassword: 'getLoadingStateForgotPassword',
    }),
  },
  methods: {
    ...mapMutations('event', {
      clearEventList: 'CLEAR_USER_EVENT_LIST',
    }),
    ...mapMutations('group', {
      clearGroupList: 'CLEAR_USER_GROUP_LIST',
    }),
    ...mapMutations('club', {
      clearClubList: 'CLEAR_USER_CLUB_LIST',
    }),
    ...mapMutations('activity', {
      clearActivityList: 'CLEAR_USER_ACTIVITY_LIST',
    }),
    ...mapActions('event', {
      getUserEvents: 'FETCH_DATA_USER_EVENT_LIST',
    }),
    ...mapActions('group', {
      getUserGroups: 'FETCH_DATA_USER_GROUP_LIST',
    }),
    ...mapActions('club', {
      getUserClubs: 'FETCH_DATA_USER_CLUB_LIST',
    }),
    ...mapActions('activity', {
      getUserActivities: 'FETCH_DATA_USER_ACTIVITY_LIST',
    }),
    ...mapActions('account', {
      forgotPasswordAction: 'FORGOT_PASSWORD',
    }),
    ...mapActions('activity', {
      removeActivity: 'DELETE_ACTIVITY',
    }),
    loadMoreEvents() {
      this.eventPage += 1;

      const filter = _.clone(this.getUserEventFilter);
      filter.page = this.eventPage;
      filter.pageSize = 3;
      filter.loadButton = true;
      this.getUserEvents(filter);
    },
    loadMoreClubs() {
      this.clubPage += 1;

      const filter = _.clone(this.getUserClubFilter);
      filter.page = this.clubPage;
      filter.pageSize = 6;
      filter.loadButton = true;
      this.getUserClubs(filter);
    },
    loadMoreGroups() {
      this.groupPage += 1;

      const filter = _.clone(this.getUserGroupFilter);
      filter.page = this.groupPage;
      filter.pageSize = 6;
      filter.loadButton = true;
      this.getUserGroups(filter);
    },
    loadMoreActivities() {
      this.activityPage += 1;

      const filter = _.clone(this.getUserActivityFilter);
      filter.page = this.activityPage;
      filter.loadButton = true;
      this.getUserActivities(filter);
    },
    refreshActivities() {
      this.clearActivityList();
      const filter = _.clone(this.getUserActivityFilter);
      filter.page = 1;
      filter.pageSize = this.activityPage * 2;
      filter.loadButton = true;
      this.getUserActivities(filter);
    },
    closeModal() {
      if (this.confirmationModalIsActive) {
        this.confirmationModalIsActive = !this.confirmationModalIsActive;
      } else {
        this.showConfirmationModal = false;
      }
    },
    showConfirmationModalFunction(rowData) {
      this.activityId = rowData;
      this.confirmationModalIsActive = true;
      this.showConfirmationModal = true;
    },
    deleteActivity() {
      const paramsObj = {
        activityId: this.activityId,
      };
      this.closeModal();
      this.removeActivity(paramsObj).then(() => {
        this.refreshActivities();
        this.$toasted.show('Activiteit werd succesvol verwijderd.', { type: 'success' });
      }).catch((error) => {
        this.$toasted.show(...error.response.data);
      });
    },
    changePassword() {
      const emailObj = { email: this.currentUser.email };
      this.forgotPasswordAction(emailObj)
        .then(() => {
          this.$toasted.show(`Een e-mail om het wachtwoord te wijzigen is verstuurd naar ${this.currentUser.email}.`, { type: 'success' });
        }).catch((error) => {
          this.$toasted.show(...error.response.data);
        });
    },
  },
  created() {
    this.clearGroupList();
    this.clearClubList();
    this.clearEventList();
    this.clearActivityList();

    const filterEvents = _.clone(this.getUserEventFilter);
    filterEvents.page = this.page;
    this.getUserEvents(filterEvents);

    const filterGroups = _.clone(this.getUserGroupFilter);
    filterGroups.page = this.page;
    this.getUserGroups(filterGroups);

    const filterClubs = _.clone(this.getUserClubFilter);
    filterClubs.page = this.page;
    this.getUserClubs(filterClubs);

    const filterActivities = _.clone(this.getUserActivityFilter);
    filterActivities.page = this.page;
    this.getUserActivities(filterActivities);
  },
};
